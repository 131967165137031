import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-inline-block ma-3"},[_c('router-link',{attrs:{"to":{ name: 'blogPost', params: { slug: _vm.slug, blogPost: _vm.blogPost } }}},[_c(VImg,{attrs:{"src":(_vm.blogPost && _vm.blogPost.image) || require('@/assets/logo.png'),"min-height":"100px"},on:{"load":function($event){_vm.isLoading = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VCol,[_c(VIcon,{domProps:{"textContent":_vm._s('fas fa-circle-notch fa-spin')}})],1)],1)]},proxy:true}])})],1),(!_vm.isLoading && _vm.blogPost && _vm.blogPost.title)?_c(VCardTitle,{staticClass:"align-end fill-height blog-post-card__title"},[_vm._v(" "+_vm._s(_vm.blogPost.title)+" ")]):_vm._e(),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.blogPost.subtitle)+" ")]),_c(VCardText,[_c(VDivider),_c('p',{staticClass:"text-subtitle-2 text-right mb-0"},[_vm._v(_vm._s(_vm.blogPost.formattedCreatedDate))]),_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.blogPost.tags),function(tag){return _c(VChip,{key:tag.id,staticClass:"text-uppercase",on:{"click":function($event){return _vm.loadTagPage(tag)}}},[_vm._v(_vm._s(tag.name))])}),1)],1),_c(VCardText,{staticClass:"text--primary blogPost",domProps:{"innerHTML":_vm._s(_vm.blogPost && _vm.blogPost.content && ((_vm.blogPost.content.substring(0, Math.ceil(Math.random() * 200) + 50)) + "..."))}}),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","to":{ name: 'blogPost', params: { slug: _vm.slug, blogPost: _vm.blogPost } }}},[_vm._v(" Read more... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }